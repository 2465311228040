import React from "react"
import AsyncStorage from "@react-native-async-storage/async-storage"
import { loginStore, 유저정보 } from "@zigbang/screens/lib/Account"
import { AccountUrl, showAccountDialog } from "@zigbang/screens/lib/Account/controlAccountDialog"
import { Constants } from "@zigbang/screens/lib/Constants"
import { JSEmitter, JSEmitterEvent } from "@zigbang/screens/lib/JSEmitter"
import { injectException } from "@zigbang/screens/lib/navigation/injectException"
import { HouseRouterName } from "@zigbang/screens/routes/house/types"
import { OfficetelRouterName } from "@zigbang/screens/routes/officetel/types"
import { OneroomRouterName } from "@zigbang/screens/routes/oneroom/types"
import { StoreRouterName } from "@zigbang/screens/routes/store/types"
import { VillaRouterName } from "@zigbang/screens/routes/villa/types"
import { Url } from "@zigbang/utils"
import { Router } from "../../../routes"
import { mapSubMenuStatus, MapType } from "../config"
import SettingDialog from "../setting/SettingDialog"
import { HeaderStyle } from "./Header.style"

export class Header extends React.PureComponent<{
	asPath: string
	route: string
	mode: "relative" | "fixed"
}> {
	state: {
		initMenu: boolean
		selectedD1: any
		selectedD2: any
		userInfo?: 유저정보
	} = {
		initMenu: false,
		selectedD1: undefined,
		selectedD2: undefined,
		userInfo: undefined,
	}

	async componentDidMount() {
		if (!this.state.initMenu) {
			const result: any = this.initGnbMenu()

			this.setState({
				selectedD1: result.selectedD1,
				selectedD2: result.selectedD2,
				initMenu: true,
			})
			this.fetchUserInfo()
		}
	}

	fetchUserInfo = async () => {
		const userInfo = await loginStore.내정보({ 지금당장_데이터_갱신: true })
		this.setState({ userInfo })
		return userInfo
	}

	initGnbMenu() {
		const { asPath, route } = this.props

		const mapType = mapSubMenuStatus.get() as MapType
		const selectedD1 =
			menuArr.find((v) => (v.values.pathMatch || /^$/).test(asPath)) ||
			(pathForStorageData.find((v) => v === route)
				? menuArr.find((v) => (v.values.storageMatch || /^$/).test(mapType))
				: undefined)
		const selectedD2 =
			(selectedD1 &&
				selectedD1.values.children &&
				selectedD1.values.children.find((v) => (v.values.pathMatch || /^$/).test(asPath))) ||
			(pathForStorageData.find((v) => v === route)
				? selectedD1 &&
					selectedD1.values.children &&
					selectedD1.values.children.find((v) => (v.values.storageMatch || /^$/).test(mapType))
				: undefined)

		return { selectedD1, selectedD2 }
	}

	showSettingDialog = () => {
		showAccountDialog({ url: AccountUrl.SETTING })
	}

	render() {
		let { selectedD1 } = this.state
		let { selectedD2 } = this.state
		const { userInfo } = this.state

		if (this.state.initMenu) {
			const result: any = this.initGnbMenu()

			selectedD1 = result.selectedD1
			selectedD2 = result.selectedD2
		}

		return (
			<>
				<HeaderStyle mode={this.props.mode}>
					<div className="top">
						<div className="logo-wrap">
							<a href="#" onClick={() => Router.pushRoute("Index")}>
								<img
									className="logo"
									src={`${process.env.S_HOST}/v1/web/common/new/zigbangWeb_horizontal_on-line_new.png`}
									alt="직방"
								/>
							</a>
						</div>
						<div className="gnb">
							<ul id="gnb-container">
								{menuArr.map((v) => (
									<li key={v.key} className={`has_d2 ${v === selectedD1 ? "on" : ""}`}>
										<a href={v.values.href ?? "#"} onClick={v.values.onPress}>
											{v.values.title}
										</a>
										{v.values.children && (
											<div className="depth2_bx">
												{v.values.children.map((v2) => (
													<a
														key={v2.key}
														className={`${v2 === selectedD2 ? "on" : ""} ${
															v2.values.comingSoon ? "coming_soon" : ""
														}`}
														href={v2.values.href ?? "#"}
														onClick={v2.values.onPress}>
														{v2.values.title}
													</a>
												))}
											</div>
										)}
									</li>
								))}
							</ul>
						</div>
						{userInfo && (
							<div className={userInfo.user_no ? "top_btn top_btn_login" : "top_btn"}>
								<div className="top_btn_menu">
									{userInfo.user_no ? (
										<div className="bx_user">
											<button type="button" className="i_user">
												{userInfo.name || userInfo.email}
											</button>
											<div className="bx_wrap">
												<div className="depth2_bx">
													{userInfo.user_type === "3" ? (
														<>
															<a
																href={`${
																	process.env.STAGE === "prod"
																		? "https://ceo.zigbang.com/my"
																		: "https://ceo.zigbang.net/my"
																}`}>
																계정 기본정보
															</a>
															<a onClick={this.showSettingDialog}>설정 (로그아웃)</a>
														</>
													) : (
														<a onClick={this.showSettingDialog}>설정 (로그아웃)</a>
													)}
												</div>
											</div>
										</div>
									) : (
										<div className="bx_user">
											<button
												type="button"
												className="i_account"
												onClick={() => {
													injectException.route("LoginModal", {})
												}}>
												로그인 및 회원가입
											</button>
										</div>
									)}
								</div>
								{this.render중개사관련버튼()}
							</div>
						)}
					</div>
					{selectedD1 && selectedD1.values.children && (
						<div className="bot depth2 apt_depth2">
							{selectedD1.values.children.map((v: any) => (
								<a
									key={v.key}
									className={`${v === selectedD2 ? "on" : ""} ${
										v.values.comingSoon ? "coming_soon" : ""
									}`}
									href={v.values.href ?? "#"}
									onClick={v.values.onPress}>
									{`${v.values.comingSoon ? `${v.values.title} (준비중)` : v.values.title}`}
								</a>
							))}
						</div>
					)}
				</HeaderStyle>
				<SettingDialog updateUserInfo={this.fetchUserInfo} />
			</>
		)
	}

	render중개사관련버튼() {
		const { userInfo } = this.state
		if (userInfo?.user_type === "3") {
			return (
				<div className="agent_user_btn" style={{ backgroundColor: "#fff" }}>
					<button
						type="button"
						className="i_account"
						onClick={async () => {
							try {
								const accessToken = await loginStore.getAccessToken()
								if (!accessToken) {
									return
								}
								location.href = Url.updateQueryStringParams(
									`${process.env.ACCOUNT_HOST}/v2/oauth/proxy`,
									{
										domain: process.env.CEO_DOMAIN,
										code: accessToken,
										client_id: "zigbang-ceo",
										redirect_url: `${process.env.CEO_HOST}/OAuth/Callback`,
									}
								)
							} catch (e) {
								//
							}
						}}>
						중개사 사이트 이동
					</button>
				</div>
			)
		}
		return (
			<div className="top_btn_menu">
				<a href="/home/RegisterInfo" className="i_img_link">
					<img
						src={`${process.env.S_HOST}/v1/web/main/ceo_ad_img_new.png`}
						width="126"
						height="38"
						alt="중개사무소 가입 및 광고문의"
					/>
				</a>
			</div>
		)
	}
}

const menuArr: Menu[] = [
	{
		key: "homeApt",
		values: {
			title: (
				<>
					<span>아파트</span>
					<span className="small">매매/전월세/신축분양</span>
				</>
			),
			onPress: () => Router.pushRoute("HomeApt"),
			pathMatch: /^\/home\/apt/,
			storageMatch: /^(apt|offer)$/,
			children: [
				{
					key: "homeAptMap",
					values: {
						title: "매매/전·월세",
						onPress: () => Router.pushRoute("HomeAptMap"),
						pathMatch: /^\/home\/apt\/(map($|\?.+))/,
						storageMatch: /^apt$/,
					},
				},
				{
					key: "homeAptMapOffer",
					values: {
						title: "신축분양",
						onPress: () => Router.pushRoute("HomeAptMapOffer"),
						pathMatch: /^\/home\/apt\/map\/offer/,
						storageMatch: /^offer$/,
					},
				},
				{
					key: "HomeAptMapPopulation",
					values: {
						title: "인구흐름",
						onPress: () => Router.pushRoute("HomeAptMapPopulation"),
						pathMatch: /^\/home\/apt\/map\/population/,
						storageMatch: /^population$/,
					},
				},
			],
		},
	},
	{
		key: "homeVilla",
		values: {
			title: (
				<>
					<span>빌라, 투룸+</span>
					<span className="small">신축분양/매매/전월세</span>
				</>
			),
			onPress: () => {
				JSEmitter.clearListeners(JSEmitterEvent.VILLA_FILTER_CHANGED)
				Router.pushRoute(VillaRouterName.HomeVilla)
			},
			pathMatch: /^\/home\/villa/,
			storageMatch: /^(villa)$/,
			children: [
				{
					key: VillaRouterName.HomeVillaMap,
					values: {
						title: "빌라, 투룸 찾기",
						onPress: () => {
							JSEmitter.clearListeners(JSEmitterEvent.VILLA_FILTER_CHANGED)
							Router.pushRoute(VillaRouterName.HomeVillaMap)
						},
						pathMatch: /^\/home\/villa\/(map($|\?.+))/,
						storageMatch: /^villa$/,
					},
				},
				{
					key: "homeVillaZzim",
					values: {
						title: "찜한 매물",
						onPress: () => Router.pushRoute(VillaRouterName.HomeVillaItemsZzim),
						pathMatch: /^\/home\/villa\/items\/(zzim($|\?.+))/,
					},
				},
				{
					key: "homeVillaItemsAdd",
					values: {
						title: "빌라 내놓기(매매/전월세)",
						onPress: () => Router.pushRoute(HouseRouterName.SellYourHouse, { service_type: "villa" }),
					},
				},
			],
		},
	},
	{
		key: "homeOneroom",
		values: {
			title: (
				<>
					<span>원룸</span>
					<span className="small">전월세</span>
				</>
			),
			onPress: () => {
				JSEmitter.clearListeners(JSEmitterEvent.ONEROOM_FILTER_CHANGED)
				Router.pushRoute(OneroomRouterName.HomeOneroom)
			},
			pathMatch: /^\/home\/oneroom/,
			storageMatch: /^(oneroom)$/,
			children: [
				{
					key: OneroomRouterName.HomeOneroomMap,
					values: {
						title: "방 찾기",
						onPress: () => {
							JSEmitter.clearListeners(JSEmitterEvent.ONEROOM_FILTER_CHANGED)
							Router.pushRoute(OneroomRouterName.HomeOneroomMap)
						},
						pathMatch: /^\/home\/oneroom\/(map($|\?.+))/,
						storageMatch: /^oneroom$/,
					},
				},
				{
					key: "homeZzim",
					values: {
						title: "찜한 매물",
						onPress: () => Router.pushRoute(OneroomRouterName.HomeOneroomItemsZzim),
						pathMatch: /^\/home\/oneroom\/items\/(zzim($|\?.+))/,
					},
				},
				{
					key: "homeOneroomItemsAdd",
					values: {
						title: "방 내놓기(전월세만)",
						onPress: () => Router.pushRoute(HouseRouterName.SellYourHouse, { service_type: "oneroom" }),
					},
				},
			],
		},
	},
	{
		key: "homeOfficetel",
		values: {
			title: (
				<>
					<span>오피스텔</span>
					<span className="small">도시형생활주택/전월세</span>
				</>
			),
			onPress: () => {
				JSEmitter.clearListeners(JSEmitterEvent.OFFICETEL_FILTER_CHANGED)
				Router.pushRoute(OfficetelRouterName.HomeOfficetel)
			},
			pathMatch: /^\/home\/officetel/,
			storageMatch: /^(officetel)$/,
			children: [
				{
					key: OfficetelRouterName.HomeOfficetelMap,
					values: {
						title: "오피스텔 찾기",
						onPress: () => {
							JSEmitter.clearListeners(JSEmitterEvent.OFFICETEL_FILTER_CHANGED)
							Router.pushRoute(OfficetelRouterName.HomeOfficetelMap)
						},
						pathMatch: /^\/home\/officetel\/(map($|\?.+))/,
						storageMatch: /^officetel$/,
					},
				},
				{
					key: "homeOfficetelZzim",
					values: {
						title: "찜한 매물",
						onPress: () => Router.pushRoute(OfficetelRouterName.HomeOfficetelItemsZzim),
						pathMatch: /^\/home\/officetel\/items\/(zzim($|\?.+))/,
					},
				},
				{
					key: "homeOfficetelItemsAdd",
					values: {
						title: "오피스텔 내놓기(전/월세만)",
						onPress: () => Router.pushRoute(HouseRouterName.SellYourHouse, { service_type: "officetel" }),
					},
				},
			],
		},
	},
	{
		key: "homeStore",
		values: {
			title: (
				<>
					<span>상가</span>
					<span className="small">임대/매매</span>
				</>
			),
			onPress: () => Router.pushRoute(StoreRouterName.HomeStore),
			pathMatch: /^\/home\/store/,
			storageMatch: /^(store)$/,
			children: [
				{
					key: StoreRouterName.HomeStoreMap,
					values: {
						title: "상가・점포",
						onPress: () => Router.pushRoute(StoreRouterName.HomeStoreMap),
						pathMatch: /^\/home\/store\/(map($|\?.+))/,
						storageMatch: /^store$/,
					},
				},
				{
					key: "homeOfficeMap",
					values: {
						title: "사무실",
						onPress: () => {
							//
						},
						comingSoon: true,
					},
				},
				{
					key: "homeCoworkingSpaceMap",
					values: {
						title: "공유오피스",
						onPress: () => {
							//
						},
						comingSoon: true,
					},
				},
				{
					key: "HomeStoreItemsWebZzim",
					values: {
						title: "찜한 매물",
						onPress: () => Router.pushRoute("HomeStoreItemsWebZzim"),
						pathMatch: /^\/home\/store\/(zzim($|\?.+))/,
						storageMatch: /^store$/,
					},
				},
				{
					key: "homeStoreItemsAdd",
					values: {
						title: "상가 내놓기",
						onPress: () => injectException.route(StoreRouterName.HomeStoreItemsAdd, {}),
					},
				},
			],
		},
	},
]

if (process.env.STAGE === "dev" || process.env.STAGE === "preview") {
	menuArr.push({
		key: "homeDeveloper",
		values: {
			title: (
				<>
					<span>개발자</span>
					<span className="small">WARNING</span>
				</>
			),
			onPress: () => {
				//
			},
			pathMatch: /^\/home\/developer/,
			storageMatch: /^(developer)$/,
			children: [
				{
					key: "HomeDeveloperPerformance",
					values: {
						title: "속도측정 패널 토글",
						onPress: () => {
							JSEmitter.emit(JSEmitterEvent.TOGGLE_PERFORMANCE_PANEL)
						},
					},
				},
				{
					key: "SHOW_RADIUS_CIRCLE_MAP",
					values: {
						title: "지도 VIP 범위 토글",
						onPress: async () => {
							const val = await AsyncStorage.getItem(Constants.Keys.SHOW_RADIUS_CIRCLE_MAP)
							const onOrOff = val === "true" ? "false" : "true"
							await AsyncStorage.setItem(Constants.Keys.SHOW_RADIUS_CIRCLE_MAP, onOrOff.toString())
							window.location.reload()
						},
					},
				},
			],
		},
	})
}

const pathForStorageData = [
	"/contact",
	"/settings/cs",
	"/home/apt_danjis_detail",
	"/home/apt_items_add_intro",
	"/home/apt_items_add_search",
	"/home/apt_items_add_dongho",
	"/home/apt_items_add_incoming",
	"/home/apt_items_list",
	"/home/apt_favorite_item_list",
	"/home/apt_items_detail",
	"/home/apt_schools_detail",
	"/home/apt_schools_danjis",
	"/home/apt_subways_detail",
	"/home/apt_subways_danjis",
	"/home/apt_locals_detail",
	"/home/apt_locals_danjis",
	"/home/apt_danjis_reviews_add",
	"/home/apt_danjis_reviews",
	"/profiles/detail",
	"/home/message",
	"/home/villa_building_detail",
	"/home/villa_danjis_detail",
	"/home/villa_inquiry_tour",
	"/home/villa_items_detail",
	"/home/villa_schools_detail",
	"/home/villa_subways_items",
	"/home/items_photos",
	"/home/oneroom_items_detail",
	"/home/oneroom_schools_detail",
	"/home/oneroom_subways_items",
	"/home/officetel_danjis_items",
	"/home/officetel_items_detail",
	"/home/officetel_schools_detail",
	"/home/officetel_subways_items",
	"/home/store_items_detail",
	"/home/store_items",
	"/home/safe_agent_list",
]

interface Menu {
	key: string
	values: {
		title: string | JSX.Element
		onPress?: () => any
		pathMatch?: RegExp
		storageMatch?: RegExp
		href?: string
		children?: Menu[]
		comingSoon?: boolean
	}
}
