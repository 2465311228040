import { NextRouter } from "next/router"
import React from "react"
import { ParamListBase, RouteProp } from "@react-navigation/native"
import { StackNavigationOptions } from "@react-navigation/stack"
import { HomeVillaMapScreen } from "@zigbang/screens/home/villa/HomeVillaMapScreen"
import { LayoutOptions, ReactNavigationMapper } from "@zigbang/screens/lib/navigation/ReactNavigationMapper"
import { SelectedMarker } from "@zigbang/screens/lib/WebMapStore"
import { VillaRouterName } from "@zigbang/screens/routes/villa/types"
import { Rect } from "@zigbang/utils"

import { ContentLayer, DetailArea, Root } from "./AptMapOfferLayoutStyle"
import MapWrapper from "./MapWrapper"
import { PageContainerV2 } from "./PageContainerV2"

export class VillaLayout extends React.Component<Props> {
	static layoutOptions = {
		noFooter: true,
	}

	static cloneInstance = (inst: any, additionalProps?: { [key: string]: any }) =>
		Object.assign(Object.create(Object.getPrototypeOf(inst)), inst, additionalProps)

	state: State = {
		rect: undefined,
		zoom: undefined,
		selectMarkerData: undefined,
	}
	dragging = false
	mapScreen: any = React.createRef()

	render() {
		const { navigation, rightScreen: Component, router, navigationRoute, routeName, ...restProps } = this.props
		if (!router || !router.pathname) {
			return null
		}
		let { query } = this.props
		if (
			this.props.router.pathname === "/home/villa_items" ||
			this.props.router.pathname === "/home/villa_map" ||
			this.props.router.pathname === "/home/villa_subways_items"
		) {
			query = {
				...this.props.query,
				...this.state.rect,
				zoom: this.state.zoom,
			}
		}
		query.clusterSelectedTime = this.state.clusterSelectedTime

		const pageContainer = (
			<PageContainerV2
				{...{ router, Component, routeName, ...restProps }}
				query={query}
				asPath={this.props.asPath}
				updateMarker={this.updateMarker}
			/>
		)
		return (
			<Root>
				<MapWrapper
					MapComponent={HomeVillaMapScreen}
					innerRef={this.mapScreen}
					navigation={navigation}
					route={navigationRoute}
					pathname={this.props.router.pathname}
					onPanDrag={this.onPanDrag}
					onRegionChangeComplete={this.onRegionChangeComplete}
					onClusterPress={this.onClusterPress}
					selectMarkerData={this.state.selectMarkerData}
				/>
				{/* <MapFilter searchResult={this.onClickSearchResult} mode={MapType.villa} /> */}
				<DetailArea>
					<ContentLayer>{pageContainer}</ContentLayer>
				</DetailArea>
			</Root>
		)
	}
	onRegionChangeComplete = (rect: Rect, zoom?: number) => {
		if (!this.dragging) {
			this.setState({
				rect,
				zoom,
				...(zoom ? { clusterSelectedTime: undefined } : undefined),
			})
			return
		}
		if (this.props.router.pathname !== "/home/villa_items" && this.props.router.pathname !== "/home/villa_map") {
			this.props.navigation.push(VillaRouterName.HomeVillaMap)
		}
		this.dragging = false
		this.setState({
			rect,
			zoom,
			clusterSelectedTime: undefined,
		})
	}
	onClickSearchResult = (item: { type: string; value: any }) => {
		if (item.type === "map") {
			if (this.mapScreen.current) {
				this.mapScreen.current.wrappedInstance.moveToRegion(item.value)
			}
		} else {
			this.props.navigation.push("HomeAptDanjisDetail", { area_danji_id: item.value })
		}
	}
	onPanDrag = () => (this.dragging = true)

	onClusterPress = () => {
		this.setState({
			clusterSelectedTime: new Date().getTime(),
		})
	}

	updateMarker = ({ latitude, longitude, id, markerType, fixedZoom, exception, zoom }: SelectedMarker) => {
		const { isSSR, moveRegion } = this.props

		if ((exception || !isSSR) && !moveRegion) {
			return
		}
		this.setState({ selectMarkerData: { latitude, longitude, id, markerType, fixedZoom, zoom } })
	}
}

interface Props {
	navigation: ReactNavigationMapper
	navigationRoute: RouteProp<ParamListBase>
	rightScreen: React.ComponentType<any> & {
		navigationOptions: StackNavigationOptions
		layoutOptions: LayoutOptions
	}
	router: NextRouter
	area_danji_id?: string
	routeName: string
	asPath: string
	query: { [key: string]: unknown }
	isSSR?: boolean
	moveRegion?: boolean
}

interface State {
	rect?: Rect
	zoom?: number
	selectMarkerData?: SelectedMarker
	clusterSelectedTime?: number
}
